import { render, staticRenderFns } from "./HomeFooter.vue?vue&type=template&id=54ec4a27&scoped=true"
import script from "./HomeFooter.vue?vue&type=script&lang=js"
export * from "./HomeFooter.vue?vue&type=script&lang=js"
import style0 from "./HomeFooter.vue?vue&type=style&index=0&id=54ec4a27&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ec4a27",
  null
  
)

export default component.exports